<template>
    <data></data>
</template>

<script>
import { doSpiderRequest } from '@/utils/spiderRequest'

export default {
    data() {
        return {
            flag: false,
        }
    },
    methods: {
        initData() {
            doSpiderRequest('/spider/getTask', {}, this.$route.query).then((res) => {
                console.log(res);
                this.flag = true;
                window.open(res.source_url, '_self');
                // window.close();
            })
            setTimeout(function() {
                if (!this.flag) {
                    this.initData();
                }
            }, 10000)
        }
    },
    mounted() {
        this.initData()
    }
}
</script>